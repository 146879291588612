import {ApiConfig} from "../type/Base";
import {RequestHelper} from "./RequestHelper";
import {ErrorHandler} from "./ErrorHandler";

export class ManagerApi {

    private apiConfig: ApiConfig
    private requestHelper: RequestHelper
    private errorHandler: ErrorHandler

    constructor(apiConfig: ApiConfig, requestHelper: RequestHelper, errorHandler: ErrorHandler) {
        this.apiConfig = apiConfig;
        this.requestHelper = requestHelper;
        this.errorHandler = errorHandler;
    }

    getFrontendConfiguration = (): Promise<ApiConfig> => {
        return this.requestHelper.fetch(this.apiConfig.manager + "frontend/configuration").then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    getOTPSecret = (): Promise<string> => {
        return this.requestHelper.fetch(this.apiConfig.manager + "token").then(response => {
            if (response.ok) {
                return response.text();
            } else {
                //TODO Add error handling
                return Promise.resolve("");
            }
        })
    }


    uploadAvatar = (file: File): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.manager + "avatar", {
            method: "POST",
            body: file
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }
}