import {FileUploadDropzone} from "../../compoments/FileUploadDropzone";
import React, {useContext, useState} from "react";
import {Card, Grid, GridCol, Menu} from "@mantine/core";
import {FileWithPath} from "@mantine/dropzone";
import {APIManagerContext} from "../../api/APIManager";
import {ContentGridElement} from "../../type/Content";
import SelectTagModal from "../../compoments/content/SelectTagModal";
import ContentGridItem from "../../compoments/content/ContentGridItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ContentUpload() {
    const {contentApi} = useContext(APIManagerContext);

    const [tags, setTags] = useState<ContentGridElement[]>([])

    function handleUpload(files: FileWithPath[]) {
        Promise.all(files.map(file => {

            let tagIds = tags.map(tag => tag.id);
            return contentApi?.uploadContent(file, tagIds);
        })).then(responses => {

        })
    }

    function addTag(tagId: string | undefined) {
        if (tagId) {
            contentApi?.getTag(tagId).then(tag => {
                setTags([...tags, {
                    id: tag.id,
                    label: tag.label,
                    parent: [tag.parent],
                    thumbnail: tag.thumbnail,
                    type: "tag"
                }])
            })
        }
    }

    function removeTagMenuItem(id: string): React.ReactNode[] {
        return [(<Menu.Item key={"delete"} color="red" leftSection={<FontAwesomeIcon icon={faTrash}/>}
                            onClick={() => {
                                setTags(tags.filter(tag => tag.id !== id))
                            }}>Remove Tag</Menu.Item>)];
    }

    return (
        <Card>
            <SelectTagModal afterAction={selected => addTag(selected)}/>
            <Grid>
                <GridCol span={{base: 12, md: 6}}>
                    <FileUploadDropzone onDrop={(files) => handleUpload(files)}
                                        onReject={(files) => console.log('rejected files', files)}/>
                </GridCol>
                <GridCol span={{base: 12, md: 6}}>
                    <Grid>
                        {tags.map(tag => {
                            return (
                                <ContentGridItem key={tag.id} content={tag}
                                                 hideDelete
                                                 afterAction={() => {
                                                 }}
                                                 additionalMenuItems={removeTagMenuItem}
                                                 colSpan={{base: 6}}
                                                 onClick={(event, content) => {
                                                     event.preventDefault();
                                                 }}/>
                            )
                        })}
                    </Grid>
                </GridCol>
            </Grid>

        </Card>
    )
}