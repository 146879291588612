import {Center, Pagination} from "@mantine/core";
import React from "react";

interface PaginationProps {
    onChange: (page: number) => void
    total: number,
    value: number
}

export default function LegionPagination({onChange, total, value}: Readonly<PaginationProps>) {

    return (
        <Center pt={"xs"} pb={"xs"}>
            <Pagination visibleFrom={"xs"} total={total} value={value}
                        onChange={(page) => {
                            onChange(page);
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0
                            });
                        }} siblings={2}/>

            <Pagination hiddenFrom={"xs"} total={total} value={value}
                        onChange={(page) => {
                            onChange(page);
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0
                            });
                        }} siblings={1} gap={4}/>

        </Center>
    )
}