import {Container} from "@mantine/core";
import React from "react";
import ShopTable from "../../compoments/shopper/shop/ShopTable";

export default function Shops() {
    return (
        <Container fluid>
            <ShopTable/>
        </Container>
    );
}