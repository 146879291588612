import {ActionIcon, Menu} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsisVertical,
    faEye,
    faPenToSquare,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {useNavigate} from "react-router-dom";
import {ContentGridElement} from "../../type/Content";

interface ContentGridItemMenuProps {
    content: ContentGridElement
    afterAction: () => void
    hideDelete?: boolean
    additionalMenuItems?: (id: string) => React.ReactNode[]
}


export default function ContentGridItemMenu({
                                                content,
                                                afterAction,
                                                hideDelete,
                                                additionalMenuItems
                                            }: Readonly<ContentGridItemMenuProps>) {
    const {contentApi} = useContext(APIManagerContext)
    const navigate = useNavigate()


    function handleDelete() {
        contentApi?.deleteContent(content.id).then(response => {
            afterAction()
        })
    }

    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <ActionIcon bg={"transparent"} size={"xl"}>
                    <FontAwesomeIcon size={"2x"} icon={faEllipsisVertical}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item leftSection={<FontAwesomeIcon icon={faEye}/>}
                           onClick={() => navigate("/content/view/" + content.id)}>
                    Open
                </Menu.Item>
                <Menu.Item leftSection={<FontAwesomeIcon icon={faPenToSquare}/>}
                           onClick={() => navigate("/content/view/" + content.id + "#edit")}>
                    Edit
                </Menu.Item>

                <Menu.Divider/>

                <Menu.Item color="red" leftSection={<FontAwesomeIcon icon={faTrash}/>} onClick={() => handleDelete()}
                           hidden={hideDelete}>
                    Delete
                </Menu.Item>
                {additionalMenuItems ? additionalMenuItems(content.id) : ""}
            </Menu.Dropdown>
        </Menu>
    )
}