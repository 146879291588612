import {useDisclosure} from "@mantine/hooks";
import React, {useContext, useEffect} from "react";
import {Button, Drawer, Group, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {APIManagerContext} from "../../../api/APIManager";
import {Collection} from "../../../type/Shopper";


interface CreateCollectionProps {
    onClose?: () => void
    //id of the shop to edit
    edit?: string
}


export default function CreateCollection(props: Readonly<CreateCollectionProps>) {
    const [opened, {open, close}] = useDisclosure(false);

    const {shopperApi} = useContext(APIManagerContext);

    const form = useForm<Collection>({
        initialValues: {
            id: '',
            name: ''
        },
    });

    useEffect(() => {
        if (props.edit) {
            open();
            shopperApi?.getCollection(props.edit).then(response => {
                form.setValues(response);
            })
        }
    }, [props.edit]);

    function handleSubmit(collection: Collection) {
        //TODO Add Error Handling
        shopperApi?.createCollection(collection).then(item => {
            handleClose();
        })
    }

    function handleClose() {
        form.reset();
        close();
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <>
            <Drawer opened={opened} onClose={handleClose} title="Collection Shop">
                <form onSubmit={form.onSubmit((values) => handleSubmit({...values} as Collection))}>
                    <TextInput
                        required
                        withAsterisk
                        label="Name"
                        placeholder="Collection Name"
                        {...form.getInputProps('name')}
                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Drawer>

            <Button onClick={open} fullWidth>Create new</Button>
        </>
    );
}