import React from "react";
import {ShopperApi} from "./ShopperApi";
import {ApiConfig, KeycloakUserInfo} from "../type/Base";
import Keycloak from 'keycloak-js';
import {RequestHelper} from "./RequestHelper";
import {ErrorHandler} from "./ErrorHandler";
import {ManagerApi} from "./ManagerApi";
import {notifications} from "@mantine/notifications";
import {OtpHelper} from "./OtpHelper";
import {ContentApi} from "./ContentApi";

export class APIManager {
    shopperApi: ShopperApi | undefined;
    managerApi: ManagerApi | undefined;
    contentApi: ContentApi | undefined;

    apiConfig: ApiConfig | undefined;

    optHelper: OtpHelper | undefined;

    keycloak: Keycloak;

    requestHelper: RequestHelper;

    errorHandler: ErrorHandler;

    userInformation: KeycloakUserInfo | undefined

    ready: boolean

    onReady: () => void

    constructor(onReady: () => void) {
        this.ready = false;
        this.onReady = () => {
            onReady();
        }
        this.requestHelper = new RequestHelper(this);
        this.errorHandler = new ErrorHandler();
        this.keycloak = new Keycloak({
            url: "https://auth.hyrune.de/",
            realm: "Live",
            clientId: "local-dev",
        });
    }

    init() {
        // @ts-ignore
        this.requestHelper.fetch(window?.MANAGER_URL + "frontend/configuration").then(response => {
            if (response.ok) {
                response.json().then(json => {
                    this.apiConfig = json;


                    if (this.apiConfig) {
                        //Apis
                        this.shopperApi = new ShopperApi(this.apiConfig, this.requestHelper, this.errorHandler);
                        this.managerApi = new ManagerApi(this.apiConfig, this.requestHelper, this.errorHandler);
                        this.contentApi = new ContentApi(this.apiConfig, this.requestHelper, this.errorHandler);

                        if (!this.optHelper) {
                            console.log("Create OTP Class")
                            this.optHelper = new OtpHelper(this.managerApi, this.keycloak);
                        }
                    }

                    this.keycloak.loadUserInfo().then(response => {
                        this.userInformation = response as KeycloakUserInfo;

                        this.ready = true;
                        this.onReady();
                    })
                })
            } else {
                notifications.show({
                    message: "Can't load the Frontend Configuration",
                    color: "red"
                })
                this.apiConfig = {
                    version: "0.0.1-SNAPSHOT",
                    manager: "http://172.20.10.150:8081/api/v1/",
                    shopper: "http://172.20.10.150:8080/api/v1/",
                    content: "http://172.20.10.150:8082/api/v1/"
                }
            }
        })
    }
}

export const APIManagerContext = React.createContext<APIManager>(new APIManager(() => {
}));