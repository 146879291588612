import {useDisclosure} from "@mantine/hooks";
import React, {useContext, useEffect, useState} from "react";
import {Button, ComboboxData, Drawer, Group, MultiSelect, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {APIManagerContext} from "../../../api/APIManager";
import {Shop} from "../../../type/Shopper";


interface CreateStopProps {
    onClose?: () => void
    //id of the shop to edit
    edit?: string
}


export default function CreateStop(props: Readonly<CreateStopProps>) {
    const [opened, {open, close}] = useDisclosure(false);

    const {shopperApi} = useContext(APIManagerContext);

    const form = useForm<Shop>({
        initialValues: {
            id: '',
            name: '',
            url: '',
            location: '',
            tags: []
        },
    });

    const [tagGroups, setTagGroups] = useState<ComboboxData>([])

    useEffect(() => {
        shopperApi?.listTagsGroups().then(response => {
            setTagGroups(response);
        })
    }, []);

    useEffect(() => {
        if (props.edit) {
            open();
            shopperApi?.getShop(props.edit).then(response => {
                form.setValues(response);
            })
        }
    }, [props.edit]);

    function handleSubmit(shop: Shop) {
        //TODO Add Error Handling
        shopperApi?.createShop(shop).then(item => {
            handleClose();
        })
    }

    function handleClose() {
        form.reset();
        close();
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <>
            <Drawer opened={opened} onClose={handleClose} title="Create Shop">
                <form onSubmit={form.onSubmit((values) => handleSubmit({...values} as Shop))}>
                    <TextInput
                        required
                        withAsterisk
                        label="Name"
                        placeholder="Shop Name"
                        {...form.getInputProps('name')}
                    />

                    <TextInput
                        required
                        withAsterisk
                        label="URL"
                        placeholder="Shop URL"
                        {...form.getInputProps('url')}
                    />

                    <TextInput
                        label="Location"
                        placeholder="Shop Location (Country)"
                        {...form.getInputProps('location')}
                    />

                    <MultiSelect
                        label="Item tags"
                        placeholder="Tags"
                        searchable
                        clearable
                        nothingFoundMessage="Nothing found..."
                        data={tagGroups}
                        {...form.getInputProps('tags')}
                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Drawer>

            <Button onClick={open} fullWidth>Create new</Button>
        </>
    );
}