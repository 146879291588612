import {Item} from "../../type/Shopper";
import React, {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {Card, Center, Image, Text, Tooltip} from "@mantine/core";
import {StyleProp} from "@mantine/core/lib/core/Box/style-props/style-props.types";

interface OverviewItemCardProps {
    item: Item
    onItemClick: (item: Item) => void
    section?: React.ReactNode
    imageSize?: StyleProp<React.CSSProperties['height']>,
    nameSize?: StyleProp<React.CSSProperties['height']>
}

export default function OverviewItemCard({
                                             item,
                                             onItemClick,
                                             section,
                                             imageSize,
                                             nameSize
                                         }: Readonly<OverviewItemCardProps>) {


    const {apiConfig, optHelper} = useContext(APIManagerContext);


    return (

        <Card radius="md" padding={"none"}>
            <Card.Section h={imageSize || {base: "80%", md: "90%"}}>
                <Image
                    h={"100%"}
                    onClick={() => onItemClick(item)}
                    src={optHelper?.prepareURL(apiConfig?.shopper + "image/delivery/" + item.images[0])}/>
            </Card.Section>
            <Tooltip label={item.name}>
                <Card.Section h={nameSize || {base: "20%", md: "10%"}} w={"100%"} bg={"#ae5a00"}
                              style={{overflow: "hidden"}}>
                    <Center>
                        <Text size={"sm"}>
                            {item.name}
                        </Text>
                    </Center>
                </Card.Section>
            </Tooltip>
            {section}
        </Card>
    )
}