import {Anchor, Breadcrumbs} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import {ContentPath} from "../../type/Content";
import {APIManagerContext} from "../../api/APIManager";

interface ContentPathProps {
    tag?: string
    onClick: (contentPath: ContentPath) => void
    onRootClick: () => void
}

export default function ContentPathBreadcrumb({tag, onClick, onRootClick}: Readonly<ContentPathProps>) {
    const [path, setPath] = useState<ContentPath>({id: "", label: ""});

    const {contentApi} = useContext(APIManagerContext);

    useEffect(() => {
        if (tag) {
            contentApi?.getPath(tag).then(response => {
                setPath(response)
            })
        } else {
            setPath({id: "", label: ""})
        }
    }, [tag]);

    function renderPath(contentPath: ContentPath): any {
        let paths = []
        if (contentPath.parent) {
            paths.push(renderPath(contentPath.parent))
        }

        paths.push(
            <Anchor key={contentPath.id} onClick={() => onClick(contentPath)}>
                {contentPath.label}
            </Anchor>
        )

        return (paths)
    }


    return (
        <Breadcrumbs mb={"xs"}>
            <Anchor onClick={() => onRootClick()}>ROOT</Anchor>
            {renderPath(path)}
        </Breadcrumbs>
    )
}