import {ActionIcon, Menu} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faPenToSquare, faTag,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {useNavigate} from "react-router-dom";
import {AggregateContent, ContentGridElement, ContentTag} from "../../type/Content";

interface ContentGridTagItemMenuProps {
    tag: ContentGridElement
    afterAction: () => void
    viewedContent?: AggregateContent
    hideDelete?: boolean
    additionalMenuItems?: (id: string) => React.ReactNode[]
}

export default function ContentGridTagItemMenu({
                                                   tag,
                                                   afterAction,
                                                   viewedContent,
                                                   hideDelete,
                                                   additionalMenuItems
                                               }: Readonly<ContentGridTagItemMenuProps>) {

    const {contentApi} = useContext(APIManagerContext)
    const navigate = useNavigate()


    function handleDelete() {
        contentApi?.deleteTag(tag.id).then(response => {
            afterAction()
        })
    }

    function updateTagThumbnail() {
        if (viewedContent) {
            let contentTag: ContentTag = {
                id: tag.id,
                label: tag.label,
                thumbnail: viewedContent.id
            }

            if (tag.parent) {
                let parent = tag.parent[0];
                if (parent) {
                    contentTag.parent = parent;
                }
            }

            contentApi?.createTag(contentTag).then(response => {
                afterAction();
            })
        }
    }

    let menuItems = []
    menuItems.push(<Menu.Item key={"view"} leftSection={<FontAwesomeIcon icon={faEye}/>}
                              onClick={() => navigate("/content/" + tag.id)}>Open</Menu.Item>)
    menuItems.push(<Menu.Item key={"edit"} leftSection={<FontAwesomeIcon icon={faPenToSquare}/>}>Edit</Menu.Item>)

    if (viewedContent) {
        menuItems.push(<Menu.Divider key={"div1"}/>)
        menuItems.push(<Menu.Item key={"thumbnail"} leftSection={<FontAwesomeIcon icon={faPenToSquare}/>}
                                  onClick={updateTagThumbnail}>
            Set current Content as Thumbnail</Menu.Item>)
    }

    if (!hideDelete) {
        menuItems.push(<Menu.Divider key={"div2"}/>)
        menuItems.push(<Menu.Item key={"delete"} color="red" leftSection={<FontAwesomeIcon icon={faTrash}/>}
                                  onClick={() => handleDelete()}>Delete</Menu.Item>)
    }

    if (additionalMenuItems){
        menuItems.push(additionalMenuItems(tag.id))
    }

    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <ActionIcon bg={"transparent"} size={"xl"}>
                    <FontAwesomeIcon size={"2x"} icon={faTag}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                {menuItems}
            </Menu.Dropdown>
        </Menu>
    )
}