import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Item} from "../../type/Shopper";
import {APIManagerContext} from "../../api/APIManager";
import {
    Button,
    Card, Center, Grid, GridCol,
    Image, Modal,
    NumberFormatter, Stack,
    Title
} from "@mantine/core";
import {Carousel, Embla, useAnimationOffsetEffect} from "@mantine/carousel";
import CreateItem from "../../compoments/shopper/item/CreateItem";
import BackButton from "../../compoments/BackButton";

export default function ItemViewer() {
    const {id} = useParams();

    const navigate = useNavigate();

    const {shopperApi, apiConfig, optHelper} = useContext(APIManagerContext);

    const [tags, setTags] = useState<any>({});

    const TRANSITION_DURATION = 200;
    const [opened, setOpened] = useState(false);
    const [embla, setEmbla] = useState<Embla | null>(null);
    useAnimationOffsetEffect(embla, TRANSITION_DURATION);


    const [item, setItem] = useState<Item>({
        currency: "",
        description: "",
        id: "",
        images: [],
        name: "",
        price: 0,
        shop: "",
        tags: [],
        url: ""
    });


    useEffect(() => {
        reloadContent()
    }, [id])

    function reloadContent() {
        if (id) {
            shopperApi?.getItem(id).then(response => {
                setItem(response)
            })

            shopperApi?.listTags({page: 0, size: 100}).then(response => {
                let tagNames: any = {};
                response.content.forEach(tag => {
                    tagNames[tag.id] = tag.label;
                })

                setTags(tagNames)
            })
        }
    }

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    function fullscreenModal() {
        return (
            <Modal
                opened={opened}
                fullScreen
                transitionProps={{duration: TRANSITION_DURATION}}
                withCloseButton={true}
                onClose={() => setOpened(false)}
            >
                <Carousel withIndicators getEmblaApi={setEmbla} loop>
                    {item.images.map(image => {
                        return (
                            <Carousel.Slide key={image}>
                                <Image
                                    h={"90vh"}
                                    fit="contain"
                                    src={optHelper?.prepareURL(apiConfig?.shopper + "image/delivery/" + image)}
                                />
                            </Carousel.Slide>
                        )
                    })}
                </Carousel>
            </Modal>
        )
    }

    return (
        <>
            {fullscreenModal()}
            <Card shadow="sm" padding="lg" radius="md" withBorder>
                <BackButton/>
                <Card.Section>
                    <Carousel withIndicators height={600} loop>
                        {item.images.map(image => {
                            return (
                                <Carousel.Slide key={image}>
                                    <Image
                                        onClick={() => setOpened(true)}
                                        h={600}
                                        fit="contain"
                                        src={optHelper?.prepareURL(apiConfig?.shopper + "image/delivery/" + image)}
                                    />
                                </Carousel.Slide>
                            )
                        })}
                    </Carousel>
                </Card.Section>

                <Grid>
                    <GridCol span={12}>
                        <Center>
                            <Title>{item.name}</Title>
                        </Center>
                    </GridCol>
                    <GridCol span={{base: 12, md: 8}} order={{base: 2, md: 1}}>
                        <Center>
                            {item.description}
                        </Center>
                    </GridCol>
                    <GridCol span={{base: 12, md: 4}} order={{base: 1, md: 2}}>
                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Card.Section>
                                <Center>
                                    <Title p={"xs"}>
                                        <NumberFormatter value={item.price} thousandSeparator="." decimalSeparator=","
                                                         suffix={" " + item.currency}/>
                                    </Title>
                                </Center>
                            </Card.Section>
                            <Card.Section>
                                <Center>
                                    <Stack
                                        p={"xs"}
                                        gap="xs"
                                        bg="var(--mantine-color-body)"
                                    >
                                        {item.tags.map(tag => {
                                            return (
                                                <Button variant="default" key={tag}
                                                        onClick={() => navigate("/shopper/tag/" + tag)}>{tags[tag]}</Button>
                                            )
                                        })}
                                    </Stack>
                                </Center>
                            </Card.Section>
                            <Card.Section>
                                <Center m={"xs"}>
                                    <Button mr={"xs"} fullWidth onClick={() => openInNewTab(item.url)}>Open in
                                        Shop</Button>
                                    <CreateItem title={"Edit Item"} onClose={() => reloadContent()}
                                                edit={item.id}/>
                                </Center>
                            </Card.Section>
                        </Card>
                    </GridCol>
                </Grid>
            </Card>
        </>
    );
}