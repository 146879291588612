import {EMPTY_PAGE, Page} from "../type/Base";

import {notifications} from "@mantine/notifications";


export class ErrorHandler {


    handleError(response: Response): Promise<Page<any>> {
        notifications.show({
            color: 'red',
            title: 'Fetch Error',
            message: 'Can\'t load the Data from the remote Server. (' + (response.statusText ? response.statusText : response.status) + ')'
        })
        return Promise.resolve(EMPTY_PAGE);
    }
}