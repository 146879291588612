import {Route, Routes} from "react-router-dom";
import SpaceWeather from "./pages/weather/SpaceWeather";
import Items from "./pages/shopper/Items";
import Shops from "./pages/shopper/Shops";
import ItemViewer from "./pages/shopper/ItemViewer";
import ShopViewer from "./pages/shopper/ShopViewer";
import Tags from "./pages/shopper/Tags";
import TagViewer from "./pages/shopper/TagViewer";
import Overview from "./pages/shopper/Overview";
import Collections from "./pages/shopper/Collections";
import CollectionViewer from "./pages/shopper/CollectionViewer";
import Account from "./pages/account/Account";
import ContentOverview from "./pages/content/ContentOverview";
import ContentViewer from "./pages/content/ContentViewer";
import ContentUpload from "./pages/content/ContentUpload";

export default function Router() {
    return (
        <Routes>
            <Route path={"/account"} element={<Account/>}/>
            <Route path={"/content"} element={<ContentOverview/>}/>
            <Route path={"/content/:parent"} element={<ContentOverview/>}/>
            <Route path={"/content/upload"} element={<ContentUpload/>}/>
            <Route path={"/content/view/:id"} element={<ContentViewer/>}/>
            <Route path={"/shopper"} element={<Overview/>}/>
            <Route path={"/shopper/item/"} element={<Items/>}/>
            <Route path={"/shopper/item/:id"} element={<ItemViewer/>}/>
            <Route path={"/shopper/tag/:id"} element={<TagViewer/>}/>
            <Route path={"/shopper/tag"} element={<Tags/>}/>
            <Route path={"/shopper/shop/:id"} element={<ShopViewer/>}/>
            <Route path={"/shopper/shop"} element={<Shops/>}/>
            <Route path={"/shopper/collection/:id"} element={<CollectionViewer/>}/>
            <Route path={"/shopper/collection"} element={<Collections/>}/>
            <Route path={"/weather/space"} element={<SpaceWeather/>}/>
            <Route index element={<h1>Home</h1>}/>
        </Routes>
    )
}