import React, {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {AggregateContent} from "../../type/Content";
import SelectTagModal from "./SelectTagModal";

interface AddTagToContentModalProps {
    content: AggregateContent
    afterAction: (selected: string | undefined) => void
}

export default function AddTagToContentModal({content, afterAction}: Readonly<AddTagToContentModalProps>) {

    const {contentApi} = useContext(APIManagerContext);

    function updateContent(selected: string) {
        if (selected) {
            contentApi?.updateContent({
                ...content,
                tags: [...content.tags, selected]
            })
        }
    }

    return (
        <SelectTagModal afterAction={(selected) => {
            if (selected) {
                updateContent(selected)
                afterAction(selected)
            }
        }}/>
    )
}