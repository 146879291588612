import {AggregateContent, ContentGridElement} from "../../type/Content";
import {Card, GridCol, Group, Image, Text, Tooltip} from "@mantine/core";
import ContentGridItemMenu from "./ContentGridItemMenu";
import React, {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {Link} from "react-router-dom";
import ContentGridTagItemMenu from "./ContentGridTagItemMenu";
import {StyleProp} from "@mantine/core/lib/core";
import {ColSpan} from "@mantine/core/lib/components/Grid/GridCol/GridCol";

interface ContentGridItemProps {
    content: ContentGridElement
    afterAction: () => void
    onClick?: (event: React.MouseEvent, content: ContentGridElement) => void
    colSpan?: StyleProp<ColSpan>
    viewedContent?: AggregateContent
    hideDelete?: boolean
    additionalMenuItems?: (id: string) => React.ReactNode[]
}

export default function ContentGridItem({
                                            content,
                                            afterAction,
                                            colSpan,
                                            viewedContent,
                                            onClick,
                                            hideDelete,
                                            additionalMenuItems
                                        }: Readonly<ContentGridItemProps>) {

    const {optHelper, apiConfig} = useContext(APIManagerContext);


    function renderMenu() {
        if (content.type === "content") {
            return (<ContentGridItemMenu content={content} afterAction={afterAction} hideDelete={hideDelete}
                                         additionalMenuItems={additionalMenuItems}/>)
        } else if (content.type === "tag") {
            return (<ContentGridTagItemMenu tag={content} afterAction={afterAction} viewedContent={viewedContent}
                                            hideDelete={hideDelete} additionalMenuItems={additionalMenuItems}/>)
        }
    }

    const imageURL = content.thumbnail ? optHelper?.prepareURL(apiConfig?.content + "content/delivery/thumbnail/" + content.thumbnail) : "https://placehold.co/600x400"

    const link = content.type === "tag" ? "/content/" + content.id : "/content/view/" + content.id;

    return (
        <GridCol span={colSpan}>
            <Tooltip label={content.label}>
                <Card>
                    <Link to={link} style={{textDecoration: "none"}}
                          onClick={(event) => onClick ? onClick(event, content) : {}}>
                        <Card.Section>
                            <Image mih={"100px"}
                                   src={imageURL}
                                   fallbackSrc={"https://placehold.co/600x400"}/>
                        </Card.Section>
                    </Link>

                    <Card.Section ml={"xs"} display={"inline-flex"}>
                        <Group w={"80%"}>
                            <Text size={"xl"} mt={"sm"} mb={"sm"}
                                  style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                                {content.label}
                            </Text>
                        </Group>

                        <Group justify={"end"} w={"20%"}>
                            {renderMenu()}
                        </Group>
                    </Card.Section>
                </Card>
            </Tooltip>
        </GridCol>
    )
}