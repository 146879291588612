import {Image} from "@mantine/core";
import {Content} from "../../../type/Content";
import {useContext} from "react";
import {APIManagerContext} from "../../../api/APIManager";

interface ImageViewerProps {
    content: Content
    callback?: () => void
}

export default function ImageViewer({content, callback}: Readonly<ImageViewerProps>) {
    const {optHelper, apiConfig} = useContext(APIManagerContext);

    return (<Image h={{base: "500px", md: "1000px"}} fit={"contain"}
                   src={optHelper?.prepareURL(apiConfig?.content + "content/delivery/" + content.id)}
                   onLoad={callback}/>)
}