import React, {useContext, useEffect, useState} from "react";
import {Collection} from "../../../type/Shopper";
import {EMPTY_PAGE, Page} from "../../../type/Base";
import {APIManagerContext} from "../../../api/APIManager";
import {useNavigate} from "react-router-dom";
import {ActionIcon, Grid, GridCol, ScrollArea, Table, Text, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import LegionPagination from "../../LegionPagination";
import CreateCollection from "./CreateCollection";
import {Th} from "../SortableTable";
import classes from "../Table.module.css";
import {modals} from "@mantine/modals";

export default function CollectionTable() {
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState<keyof Collection | null>("name");
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const [currentEditing, setCurrentEditing] = useState<string>();

    const [data, setData] = useState<Page<Collection>>(EMPTY_PAGE);

    const {shopperApi} = useContext(APIManagerContext);

    const navigate = useNavigate();

    useEffect(() => {
        reloadContent(data.number);
    }, [reverseSortDirection, search, sortBy]);

    function reloadContent(page?: number) {
        const newPage = page || 0;

        setCurrentEditing(undefined);
        shopperApi?.listCollections({
            page: newPage,
            size: 10,
            sort: sortBy + "," + (reverseSortDirection ? "desc" : "asc")
        }, search).then(response => {
            setData(response)
        })
    }


    const setSorting = (field: keyof Collection) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setSearch(value);
    };


    function handleDelete(id: string) {
        shopperApi?.deleteCollection(id).then(() => {
            reloadContent();
        })
    }

    const rows = data.content.map((row) => (
        <Table.Tr key={row.id}>
            <Table.Td onClick={() => navigate("/shopper/collection/" + row.id)}>{row.id.substring(0, 6)}</Table.Td>
            <Table.Td onClick={() => navigate("/shopper/collection/" + row.id)}>{row.name}</Table.Td>
            <Table.Th pt={"xs"} pb={"xs"}>
                <ActionIcon variant="filled" mr={"xs"} aria-label="Settings" onClick={(event) => {
                    setCurrentEditing(row.id)
                }}>
                    <FontAwesomeIcon icon={faPenToSquare}/>
                </ActionIcon>
                <ActionIcon variant="filled" mr={"xs"} color={"red"} aria-label="Settings" onClick={(event) => {
                    modals.openConfirmModal({
                        title: "Delete " + row.name,
                        centered: true,
                        children: (<Text> Are you sure you want to delete the Collection?</Text>),
                        labels: {confirm: 'Delete Collection', cancel: "No don't delete it"},
                        confirmProps: {color: 'red'},
                        onConfirm: () => handleDelete(row.id),
                    });
                }}>
                    <FontAwesomeIcon icon={faTrash}/>
                </ActionIcon>
            </Table.Th>
        </Table.Tr>
    ));

    return (
        <ScrollArea scrollbars={"y"}>
            <Grid>
                <GridCol span={10}>
                    <TextInput
                        placeholder="Search by name"
                        mb="md"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </GridCol>
                <GridCol span={2}>
                    <CreateCollection onClose={() => reloadContent(data.number)} edit={currentEditing}/>
                </GridCol>
            </Grid>
            <Table striped highlightOnHover withTableBorder>
                <Table.Tbody>
                    <Table.Tr>
                        <Th
                            sorted={sortBy === 'id'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('id')}
                        >
                            #
                        </Th>
                        <Th
                            sorted={sortBy === 'name'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('name')}
                        >
                            Name
                        </Th>
                        <Table.Th className={classes.th}>
                            Actions
                        </Table.Th>
                    </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>
                    {rows.length > 0 ? (
                        rows
                    ) : (
                        <Table.Tr>
                            {/**@ts-ignore*/}
                            <Table.Td>
                                <Text fw={500} ta="center">
                                    Nothing found
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
            <LegionPagination onChange={(page) => {
                reloadContent(page - 1)
            }} total={data.totalPages} value={data.number + 1}/>
        </ScrollArea>
    )
}