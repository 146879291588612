import {ApiConfig, Page, Pageable} from "../type/Base";
import {RequestHelper} from "./RequestHelper";
import {ErrorHandler} from "./ErrorHandler";
import {AggregateContent, Content, ContentGridElement, ContentPath, ContentTag} from "../type/Content";
import {FileWithPath} from "@mantine/dropzone";

export class ContentApi {

    private apiConfig: ApiConfig
    private requestHelper: RequestHelper
    private errorHandler: ErrorHandler

    constructor(apiConfig: ApiConfig, requestHelper: RequestHelper, errorHandler: ErrorHandler) {
        this.apiConfig = apiConfig;
        this.requestHelper = requestHelper;
        this.errorHandler = errorHandler;
    }

    listContentElements = (pageable: Pageable, parent?: string): Promise<Page<ContentGridElement>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        } else {
            params.set("sort", "label")
        }
        if (parent) {
            params.set("parent", parent);
        }
        return this.requestHelper.fetch(this.apiConfig.content + "content/element?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    listContent = (pageable: Pageable, tag?: string): Promise<Page<Content>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        } else {
            params.set("sort", "name")
        }
        if (tag) {
            params.set("parent", tag);
        }
        return this.requestHelper.fetch(this.apiConfig.content + "content?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    getContent = (id: string): Promise<AggregateContent> => {
        return this.requestHelper.fetch(this.apiConfig.content + "content/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    updateContent = (content: Content): Promise<Content> => {
        return this.requestHelper.fetch(this.apiConfig.content + "content/" + content.id, {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(content)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    uploadContent = (file: FileWithPath, tags?: string[]): Promise<AggregateContent> => {
        let formData = new FormData();

        formData.append("data", file);

        console.log(tags)
        if (tags) {
            formData.append("tags", new Blob([JSON.stringify(tags)], {
                type: "application/json"
            }));
        }

        return this.requestHelper.fetch(this.apiConfig.content + "content", {
            method: "POST",
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteContent = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.content + "content/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    //----- Tags ------
    listTags = (pageable: Pageable, parent?: string): Promise<Page<ContentTag>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        } else {
            params.set("sort", "label")
        }
        if (parent) {
            params.set("parent", parent);
        }
        return this.requestHelper.fetch(this.apiConfig.content + "tag?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    getTag = (id: string): Promise<ContentTag> => {
        return this.requestHelper.fetch(this.apiConfig.content + "tag/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    createTag = (tag: ContentTag): Promise<ContentTag> => {
        return this.requestHelper.fetch(this.apiConfig.content + "tag", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(tag)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteTag = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.content + "tag/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    getPath = (id: string): Promise<ContentPath> => {
        return this.requestHelper.fetch(this.apiConfig.content + "tag/path/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }
}