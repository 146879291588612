import {APIManager} from "./APIManager";
import {RequestConfig} from "../type/Base";

export class RequestHelper {

    apiManager: APIManager;

    constructor(apiManager: APIManager) {
        this.apiManager = apiManager;
    }

    fetch(url: string, config?: RequestConfig): Promise<Response> {
        let fetchConfig: RequestInit = {
            headers: {
                "Authorization": "Bearer " + this.apiManager.keycloak.token
            }
        }

        if (config) {
            fetchConfig = {...fetchConfig, method: config.method}

            if (config.body) {
                fetchConfig = {...fetchConfig, body: config.body};
            }

            if (config.headers) {
                let headers: any = fetchConfig.headers;
                config.headers.forEach((value) => {
                    headers[value.name] = value.value;
                })
                fetchConfig = {...fetchConfig, headers: headers}
            }
        }

        return fetch(url, fetchConfig);
    }
}