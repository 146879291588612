import React, {useContext, useEffect, useState} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {EMPTY_PAGE, Page} from "../../type/Base";
import {Item} from "../../type/Shopper";
import {
    AspectRatio,
    Center,
    ComboboxData,
    Container,
    Grid,
    GridCol,
    Select,
    TextInput
} from "@mantine/core";
import OverviewItemCard from "../../compoments/shopper/OverviewItemCard";
import LegionPagination from "../../compoments/LegionPagination";

interface ItemGirdProps {
    onItemClick: (item: Item) => void
}

export default function ItemGird(pros: Readonly<ItemGirdProps>) {

    const {shopperApi} = useContext(APIManagerContext);

    const [content, setContent] = useState<Page<Item>>(EMPTY_PAGE);

    const [search, setSearch] = useState<string>("");

    const [tagGroups, setTagGroups] = useState<ComboboxData>([])
    const [shopNames, setShopNames] = useState<{ label: string, value: string }[]>([]);

    const [selectedTag, setSelectedTag] = useState<string>();
    const [selectedShop, setSelectedShop] = useState<string>();

    useEffect(() => {
        reloadContent(content.number)

        shopperApi?.listTagsGroups().then(response => {
            setTagGroups(response);
        })

        shopperApi?.listShopNames().then(names => {
            let map = names.map(name => {
                return ({label: name.name, value: name.id});
            });
            setShopNames(map)
        })
    }, []);

    function reloadContent(page?: number) {
        const newPage = page ?? 0;
        shopperApi?.listItems({page: newPage, size: 18}, search, selectedShop, selectedTag).then(response => {
            setContent(response)
        })
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setSearch(value);
    };

    useEffect(() => {
        reloadContent(content.number);
    }, [search, selectedTag, selectedShop]);


    return (
        <Container fluid>
            <Center mb={"xs"}>
                <TextInput mr={"xs"}
                           placeholder="Search by name"
                           value={search}
                           onChange={handleSearchChange}
                />
                <Select
                    mr={"xs"}
                    placeholder="Search by Tag"
                    data={tagGroups}
                    searchable
                    clearable
                    onChange={(value) => {
                        setSelectedTag(value || "")
                    }}
                    defaultValue={selectedTag}
                />
                <Select
                    placeholder="Search by Shop"
                    data={shopNames}
                    searchable
                    clearable
                    onChange={(value) => {
                        setSelectedShop(value || "")
                    }}
                    defaultValue={selectedTag}
                />
            </Center>
            <Grid>
                {content.content.map(item => {
                    return (
                        <GridCol span={{base: 4, md: 2}} key={item.id}>
                            <AspectRatio ratio={9 / 16}>
                                <OverviewItemCard item={item} onItemClick={(item) => pros.onItemClick(item)}/>
                            </AspectRatio>
                        </GridCol>
                    )
                })}
            </Grid>
            <LegionPagination onChange={(page) => {
                reloadContent(page - 1)
            }} total={content.totalPages} value={content.number + 1}/>
        </Container>
    )
}