import {Progress, Box, Text, Group, SimpleGrid, NumberFormatter, Card} from '@mantine/core';
import classes from './StatsSegments.module.css';
import {MantineColor} from "@mantine/core/lib/core";
import React from "react";

export interface StatsSegmentsData {
    label: string
    count: number
    color: MantineColor
}

export interface StatsSegmentsProps {
    data: StatsSegmentsData[]
}

export default function StatsSegments({data}: Readonly<StatsSegmentsProps>) {
    const totalCount = data.map(entry => entry.count).reduce((a, c) => a + c, 0);
    const percent = totalCount / 100;

    const segments = data.map((segment) => {
        const part = segment.count / percent;
        return (
            <Progress.Section value={part} color={segment.color} key={segment.color}>
                {part > 10 && <Progress.Label>{part.toFixed(2)}%</Progress.Label>}
            </Progress.Section>
        )
    });
    const descriptions = data.map((stat) => (
        <Box key={stat.label} style={{borderBottomColor: stat.color}} className={classes.stat}>
            <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
                {stat.label}
            </Text>

            <Group justify="space-between" align="flex-end" gap={0}>
                <Text fw={700}>
                    <NumberFormatter value={stat.count} thousandSeparator="." decimalSeparator=","/>
                </Text>
                <Text c={stat.color} fw={700} size="sm" className={classes.statCount}>
                    {(stat.count / percent).toFixed(2)}%
                </Text>
            </Group>
        </Box>
    ));

    return (
        <Card withBorder radius="md" w={"100%"}>
            <Group justify="space-between">
                <Group align="flex-end" gap="xs">
                    <Text fz="xl" fw={700}>
                        <NumberFormatter value={totalCount.toFixed(2)} thousandSeparator="." decimalSeparator=","/>
                    </Text>
                </Group>
            </Group>

            <Text c="dimmed" fz="sm">
                Price by Shop
            </Text>

            <Progress.Root size={34} classNames={{label: classes.progressLabel}} mt={40}>
                {segments}
            </Progress.Root>
            <SimpleGrid cols={{base: 1, xs: 3}} mt="xl">
                {descriptions}
            </SimpleGrid>
        </Card>
    );
}