import {ScrollArea} from '@mantine/core';
import classes from './NavbarNested.module.css';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloud, faFile, faHouse, faShoppingBag} from "@fortawesome/free-solid-svg-icons";
import {LinksGroup} from "./LinksGroup";
import {UserButton} from "../UserButton";

const data = [
    {label: 'Dashboard', icon: <FontAwesomeIcon icon={faHouse}/>},
    {
        label: 'Content',
        icon: <FontAwesomeIcon icon={faFile}/>,
        links: [
            {label: 'Overview', link: '/content'},
            {label: 'Uploader', link: '/content/upload'},
        ],
    },
    {
        label: 'Shopper',
        icon: <FontAwesomeIcon icon={faShoppingBag}/>,
        links: [
            {label: 'Overview', link: '/shopper'},
            {label: 'Items', link: '/shopper/item'},
            {label: 'Shops', link: '/shopper/shop'},
            {label: 'Tags', link: '/shopper/tag'},
            {label: 'Collection', link: '/shopper/collection'},
        ],
    },
    {
        label: 'Weather',
        icon: <FontAwesomeIcon icon={faCloud}/>,
        links: [
            {label: 'Overview', link: '/weather'},
            {label: 'Forecasts', link: '/weather/forecast'},
            {label: 'Webcams', link: '/weather/webcam'},
            {label: 'Space Weather', link: '/weather/space'},
        ],
    },
];

export function NavbarNested() {
    const links = data.map((item) => <LinksGroup {...item} key={item.label}/>);

    return (
        <nav className={classes.navbar}>
            <ScrollArea className={classes.links}>
                <div className={classes.linksInner}>{links}</div>
            </ScrollArea>

            <div className={classes.footer}>
                <UserButton/>
            </div>
        </nav>
    );
}