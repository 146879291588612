import '@mantine/dropzone/styles.css';
import {FileWithPath} from "@mantine/dropzone";
import {Grid} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {ContentGridElement} from "../../type/Content";
import {useNavigate, useParams} from "react-router-dom";
import CreateTagModal from "../../compoments/content/CreateTagModal";
import {FileUploadDropzone} from "../../compoments/FileUploadDropzone";
import ContentGridItem from "../../compoments/content/ContentGridItem";
import InfiniteScroll from 'react-infinite-scroller';
import ContentPathBreadcrumb from "../../compoments/content/ContentPathBreadcrumb";

export default function ContentOverview() {

    const {parent} = useParams()

    const {contentApi} = useContext(APIManagerContext);
    const navigate = useNavigate();

    const [content, setContent] = useState<ContentGridElement[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        setLoading(true)
        setContent([])
        reloadContent()
    }, [parent,]);

    function handleUpload(files: FileWithPath[]) {

        Promise.all(files.map(file => {
            let tags: string[] = []
            if (parent) {
                tags.push(parent)
            }

            return contentApi?.uploadContent(file, tags);
        })).then(responses => {
            setLoading(true)
            setContent([])
            reloadContent()
        })
    }

    function reloadContent() {
        contentApi?.listContentElements({page: 0, size: 20}, parent).then(response => {
            setTotalElements(response.totalElements)
            setContent(response.content)
            setLoading(false)
        })
        window.scrollTo(0, 0)
    }

    if (loading) {
        return <h1>Loading...</h1>
    }

    return (
        <>
            <ContentPathBreadcrumb tag={parent} onRootClick={() => navigate('/content/')}
                                   onClick={(contentPath) => navigate('/content/' + contentPath.id)}/>
            <FileUploadDropzone onDrop={(files) => handleUpload(files)}
                                onReject={(files) => console.log('rejected files', files)}/>
            <CreateTagModal parent={parent} afterAction={() => reloadContent()}/>

            <InfiniteScroll
                pageStart={0}
                loadMore={page => {
                    contentApi?.listContentElements({page: page, size: 20}, parent).then(response => {
                        setContent([...content, ...response.content])
                    })
                }}
                hasMore={content.length < totalElements}
                loader={<div className="loader" key={0}>Loading ...</div>}
            >

                <Grid mt={"xs"}>
                    {content.map(element => (
                        <ContentGridItem key={element.id} colSpan={{base: 6, md: 3, xl: 2}} content={element}
                                         afterAction={reloadContent}/>
                    ))}
                </Grid>
            </InfiniteScroll>
        </>
    )
}