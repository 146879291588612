import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Tag} from "../../type/Shopper";
import {APIManagerContext} from "../../api/APIManager";
import {
    Card, Center,
    Title
} from "@mantine/core";
import ItemTable from "../../compoments/shopper/item/ItemTable";

export default function TagViewer() {
    const {id} = useParams();

    const {shopperApi} = useContext(APIManagerContext);

    const [tag, setTag] = useState<Tag>({id: "", label: "", parent: "", tagGroup: false});


    useEffect(() => {
        if (id) {
            shopperApi?.getTag(id).then(response => {
                setTag(response)
            })
        }
    }, [id])

    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Center p={"md"}>
                <Title>{tag.label}</Title>
            </Center>
            <ItemTable tag={id}/>
        </Card>
    );
}