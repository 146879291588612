import {UnstyledButton, Group, Avatar, Text} from '@mantine/core';
import {useContext} from "react";
import {APIManagerContext} from "../api/APIManager";
import {useNavigate} from "react-router-dom";

export function UserButton() {
    const navigate = useNavigate();

    const {userInformation, optHelper, apiConfig} = useContext(APIManagerContext);

    return (
        <UnstyledButton m={"xs"}>
            <Group onClick={() => navigate("/account")}>
                <Avatar
                    src={optHelper?.prepareURL(apiConfig?.manager + "avatar/delivery/" + userInformation?.sub)}
                    radius="xl"
                />

                <div style={{flex: 1}}>
                    <Text size="sm" fw={500}>
                        {/**@ts-ignore*/}
                        {userInformation?.name}
                    </Text>

                    <Text c="dimmed" size="xs">
                        {/**@ts-ignore*/}
                        {userInformation?.email}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
    );
}