import {FileInput} from "@mantine/core";
import {useContext} from "react";
import {APIManagerContext} from "../../api/APIManager";

export default function Account() {

    const {managerApi} = useContext(APIManagerContext);

    function handleUpload(file: File) {
        managerApi?.uploadAvatar(file).then(response => {
            console.log("Uploaded")
        })
    }

    return (
        <div>
            <h1>Account</h1>
            <FileInput label={"Upload Avatar"} onChange={(file) => {
                if (file) {
                    handleUpload(file)
                }
            }}/>
        </div>
    )
}