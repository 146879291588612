import {useDisclosure} from "@mantine/hooks";
import React, {useContext, useEffect, useState} from "react";
import {Button, ComboboxData, Drawer, Group, Select, Switch, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {APIManagerContext} from "../../../api/APIManager";
import {Tag} from "../../../type/Shopper";


interface CreateStopProps {
    onClose?: () => void
    //id of the tag to edit
    edit?: string
}


export default function CreateTag(props: Readonly<CreateStopProps>) {
    const [opened, {open, close}] = useDisclosure(false);

    const {shopperApi} = useContext(APIManagerContext);

    const form = useForm<Tag>({
        initialValues: {
            id: "",
            label: "",
            parent: "",
            tagGroup: false
        },
    });

    const [tagGroups, setTagGroups] = useState<ComboboxData>([])

    useEffect(() => {
        shopperApi?.listTags({page: 0, size: 100}, "", true).then(response => {
            setTagGroups(response.content.map(tag => {
                return ({label: tag.label, value: tag.id})
            }));
        })
    }, []);

    useEffect(() => {
        if (props.edit) {
            open();
            shopperApi?.getTag(props.edit).then(response => {
                form.setValues(response);
            })
        }
    }, [props.edit]);


    function handleSubmit(tag: Tag) {
        //TODO Add Error Handling
        shopperApi?.createTag(tag).then(item => {
            handleClose();
        })
    }

    function handleClose() {
        form.reset();
        close();
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <>
            <Drawer opened={opened} onClose={handleClose} title="Create Tag">
                <form onSubmit={form.onSubmit((values) => handleSubmit({...values} as Tag))}>
                    <TextInput
                        required
                        withAsterisk
                        label="Name"
                        placeholder="Tag Label"
                        {...form.getInputProps('label')}
                    />

                    <Select
                        label="Parent"
                        placeholder="Parent"
                        searchable
                        data={tagGroups}
                        {...form.getInputProps('parent')}
                    />

                    <Switch
                        p={"xs"}
                        size="lg"
                        label="Tag Group"
                        {...form.getInputProps('tagGroup')}

                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Drawer>

            <Button onClick={open} fullWidth>Create new</Button>
        </>
    );
}