import {faChevronDown, faChevronRight, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Center, Group, rem, Table, Text, UnstyledButton} from "@mantine/core";
import classes from "./Table.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {MantineBreakpoint} from "@mantine/core/lib/core/MantineProvider";

interface ThProps {
    visibleFrom?: MantineBreakpoint;
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;

    onSort(): void;
}

export function Th({visibleFrom, children, reversed, sorted, onSort}: Readonly<ThProps>) {
    const icon = sorted ? (reversed ? faChevronUp : faChevronDown) : faChevronRight;
    return (
        <Table.Th className={classes.th} visibleFrom={visibleFrom}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <FontAwesomeIcon icon={icon} style={{width: rem(16), height: rem(16)}}/>
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}