import {Spotlight, SpotlightActionData, spotlight} from "@mantine/spotlight";
import {ActionIcon} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen, faGauge, faHouse, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

import '@mantine/spotlight/styles.css';

const actions: SpotlightActionData[] = [
    {
        id: 'home',
        label: 'Home',
        description: 'Get to home page',
        onClick: () => console.log('Home'),
        leftSection: <FontAwesomeIcon icon={faHouse} />,
    },
    {
        id: 'dashboard',
        label: 'Dashboard',
        description: 'Get full information about current system status',
        onClick: () => console.log('Dashboard'),
        leftSection: <FontAwesomeIcon icon={faGauge} />,
    },
    {
        id: 'documentation',
        label: 'Documentation',
        description: 'Visit documentation to lean more about all features',
        onClick: () => console.log('Documentation'),
        leftSection: <FontAwesomeIcon icon={faFolderOpen} />,
    },
];

export default function SpotlightSearch() {
    return (
        <>
            <ActionIcon onClick={spotlight.open} size={"xl"}><FontAwesomeIcon icon={faMagnifyingGlass}/></ActionIcon>
            <Spotlight
                onChange={(v)=>console.log(v.target)}
                actions={actions}
                nothingFound="Nothing found..."
                highlightQuery
                searchProps={{
                    leftSection: <FontAwesomeIcon icon={faMagnifyingGlass} />,
                    placeholder: 'Search...',
                }}
            />
        </>
    )
}