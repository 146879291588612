import {
    Card,
    Container,
    Grid,
    Image,
    ScrollArea,
    Center
} from "@mantine/core";
import React, {useEffect, useState} from "react";

const baseGridSize = 12;

export default function SpaceWeather() {

    const [forcast, setForcast] = useState("");


    useEffect(() => {
        fetch("https://services.swpc.noaa.gov/text/3-day-forecast.txt").then(response => {
            if (response.ok) {
                response.text().then(text => {
                    setForcast(text);
                })
            }
        })
    }, [])

    return (
        <Container>
            <Grid>
                <Grid.Col span={{base: baseGridSize, xs: 6}}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section>
                            <Image
                                src="https://services.swpc.noaa.gov/images/swx-overview-large.gif"
                            />
                        </Card.Section>
                    </Card>
                </Grid.Col>
                <Grid.Col span={{base: baseGridSize, xs: 6}}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section>
                            <Image
                                src=" https://services.swpc.noaa.gov/images/aurora-forecast-northern-hemisphere.jpg"
                            />
                        </Card.Section>
                    </Card>
                </Grid.Col>
                <Grid.Col span={{base: baseGridSize, xs: 12}}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Center>
                            <ScrollArea>
                                <div style={{whiteSpace:"pre"}}>{forcast}</div>
                            </ScrollArea>
                        </Center>
                    </Card>
                </Grid.Col>
            </Grid>
        </Container>
    )
}