import React, {useContext, useEffect, useState} from "react";
import {APIManagerContext} from "../../api/APIManager";
import {EMPTY_PAGE, Page} from "../../type/Base";
import {Collection, CollectionItem, Item} from "../../type/Shopper";
import {useNavigate, useParams} from "react-router-dom";
import {AspectRatio, Button, Card, Center, Grid, GridCol, Modal, NumberFormatter, Title} from "@mantine/core";
import BackButton from "../../compoments/BackButton";
import OverviewItemCard from "../../compoments/shopper/OverviewItemCard";
import {Embla, useAnimationOffsetEffect} from "@mantine/carousel";
import ItemGird from "../../compoments/shopper/ItemGird";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStore, faStoreSlash, faTrash} from "@fortawesome/free-solid-svg-icons";
import StatsSegments from "../../compoments/StatsSegments";
import {ProgressCardColored} from "../../compoments/ProgressCardColored";

export default function CollectionViewer() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {shopperApi} = useContext(APIManagerContext);

    const [items, setItems] = useState<Page<CollectionItem>>(EMPTY_PAGE);
    const [collection, setCollection] = useState<Collection>({createDate: new Date(), id: "", name: ""});

    const [shopNames, setShopNames] = useState<{ [key: string]: string }>({});


    const TRANSITION_DURATION = 200;
    const [opened, setOpened] = useState(false);
    const [embla, setEmbla] = useState<Embla | null>(null);
    useAnimationOffsetEffect(embla, TRANSITION_DURATION);

    useEffect(() => {
        reloadContent()
        shopperApi?.listShopNames().then(names => {

            let map = names.reduce((list, shop) => {
                // @ts-ignore
                list[shop.id] = shop.name;
                return list;
            }, {});

            setShopNames(map)
        })
    }, []);

    function reloadContent() {
        if (id) {
            //TODO Add Paging
            shopperApi?.listCollectionItems({page: 0, size:50}, id).then(response => {
                setItems(response)
            })
            shopperApi?.getCollection(id).then(response => {
                setCollection(response)
            })
        }
    }

    function fullscreenModal() {
        return (
            <Modal
                opened={opened}
                fullScreen
                transitionProps={{duration: TRANSITION_DURATION}}
                withCloseButton={true}
                onClose={() => setOpened(false)}
            >
                <ItemGird onItemClick={(item => handleAddItem(item))}/>
            </Modal>
        )
    }

    function handleAddItem(item: Item) {
        if (id) {
            shopperApi?.addCollectionItem({collection: id, item: item.id}).then(response => {
                reloadContent()
                setOpened(false)
            })
        }
    }

    function handleDelete(id: string) {
        shopperApi?.deleteCollectionItem(id).then(response => {
            reloadContent();
        })
    }

    function handlePurchase(item: CollectionItem, purchased: boolean) {
        shopperApi?.boughtCollectionItem(item.id, purchased).then(response => {
            reloadContent();
        })
    }

    function priceByPurchased() {
        let total: number = 0;
        let purchased: number = 0;

        items.content.forEach(item => {
            total = total + item.item.price;
            if (item.bought) {
                purchased = purchased + item.item.price;
            }
        })

        return {total: total, progress: purchased};
    }

    function priceByShop() {

        let groupByShop: { [p: string]: number };
        groupByShop = items.content.reduce((x, y) => {
            // @ts-ignore
            let current = x[y.item.shop];
            if (current === undefined) {
                // @ts-ignore
                x[y.item.shop] = y.item.price;
            } else {
                // @ts-ignore
                x[y.item.shop] = Number(current) + y.item.price;
            }

            return x;
        }, {});

        const colors = ["#47d6ab", "#03141a", "#4fcdf7"]

        let count = 0;

        return Object.keys(groupByShop).map(key => {
            count++;
            return ({
                label: shopNames[key],
                count: groupByShop[key],
                color: colors[count % colors.length]
            })
        })
    }

    return (
        <>
            {fullscreenModal()}
            <Card shadow={"sm"} padding={"lg"} radius={"md"} withBorder>
                <Card.Section mb={"xs"}>
                    <Center mb={"xs"}>
                        <Title>{collection.name}</Title>
                    </Center>
                    <Center>
                        <BackButton/>
                        <Button mb={"xs"} ml={"xs"} onClick={() => setOpened(true)} fullWidth>Add Item</Button>
                    </Center>
                    <Grid>
                        <GridCol span={{base: 12, md: 3}}>
                            <ProgressCardColored {...priceByPurchased()} title={"Already purchased"}
                                                 render={(value) => <NumberFormatter value={value.toFixed(2)}
                                                                                     thousandSeparator="."
                                                                                     decimalSeparator=","/>}
                            />
                        </GridCol>
                        <GridCol span={{base: 12, md: 9}}>
                            <StatsSegments data={priceByShop()}/>
                        </GridCol>
                    </Grid>
                </Card.Section>
                <Card.Section>
                    <Grid justify={"center"}>
                        {items.content.map(item => {
                            return (
                                <Grid.Col span={{base: 6, md: 2}} key={item.id}>
                                    <AspectRatio ratio={9 / 16}>
                                        <OverviewItemCard item={item.item}
                                                          onItemClick={(item) => navigate("/shopper/item/" + item.id)}
                                                          imageSize={{base: "70%", md: "80%"}}
                                                          section={
                                                              <Card.Section bg={"#ae5a00"} w={"100%"}>
                                                                  <Center>
                                                                      <Button fullWidth fz={"xs"} color={"red"}
                                                                              onClick={() => handleDelete(item.id)}>
                                                                          <FontAwesomeIcon icon={faTrash}/>
                                                                      </Button>
                                                                      {item.bought ?
                                                                          <Button fullWidth fz={"xs"} ml={"xs"}
                                                                                  onClick={() => handlePurchase(item, false)}>
                                                                              <FontAwesomeIcon icon={faStoreSlash}/>
                                                                          </Button> :
                                                                          <Button fullWidth fz={"xs"} ml={"xs"}
                                                                                  onClick={() => handlePurchase(item, true)}>
                                                                              <FontAwesomeIcon icon={faStore}/>
                                                                          </Button>
                                                                      }

                                                                  </Center>
                                                              </Card.Section>
                                                          }
                                        />
                                    </AspectRatio>
                                </Grid.Col>
                            )
                        })}
                    </Grid>
                </Card.Section>
            </Card>
        </>
    )
}