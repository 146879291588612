import {Content} from "../../../type/Content";
import {useContext} from "react";
import {APIManagerContext} from "../../../api/APIManager";

interface ImageViewerProps {
    content: Content
    callback?: () => void
}

export default function PDFViewer({content, callback}: Readonly<ImageViewerProps>) {
    const {optHelper, apiConfig} = useContext(APIManagerContext);

    return (
        <object height={"500px"} width={"100%"} data={optHelper?.prepareURL(apiConfig?.content + "content/delivery/" + content.id)}
                onLoad={callback}/>)
}