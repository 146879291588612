import {useDisclosure} from "@mantine/hooks";
import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Card,
    Drawer,
    FileInput,
    Grid,
    Group,
    Image,
    MultiSelect,
    Select,
    Textarea,
    TextInput,
    Space, ComboboxData
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {APIManagerContext} from "../../../api/APIManager";
import {Item} from "../../../type/Shopper";
import {CurrencyInput} from "../CurrencyInput";

interface CreateItemProps {
    onClose?: () => void
    preselectedShop?: string
    //id of the item to edit
    edit?: string
    title?: string
    autoOpen?: boolean
}

export default function CreateItem(props: Readonly<CreateItemProps>) {
    const [opened, {open, close}] = useDisclosure(false);

    const {shopperApi, apiConfig, optHelper} = useContext(APIManagerContext);

    const [files, setFiles] = useState<File[]>([]);
    const [knownFiles, setKnownFiles] = useState<File[]>([]);

    const [imageURL, setImageURL] = useState<string>("");

    const [shopNames, setShopNames] = useState<{ label: string, value: string }[]>([]);

    const form = useForm<Item>({
        initialValues: {
            id: '',
            name: '',
            url: '',
            shop: '',
            price: 0,
            currency: 'EUR',
            description: '',
            tags: [],
            images: []
        }
    });

    const [tagGroups, setTagGroups] = useState<ComboboxData>([])

    useEffect(() => {
        shopperApi?.listTagsGroups().then(response => {
            setTagGroups(response);
        })
    }, []);

    useEffect(() => {
        if (props.edit) {
            if (props.autoOpen) {
                open();
            }
            shopperApi?.getItem(props.edit).then(response => {
                form.setValues(response);
            })
        }
    }, [props.edit]);

    useEffect(() => {
        if (props.preselectedShop) {
            form.setFieldValue("shop", props.preselectedShop);
        }
    }, [props.preselectedShop]);

    useEffect(() => {
        shopperApi?.listShopNames().then(names => {
            let map = names.map(name => {
                return ({label: name.name, value: name.id});
            });
            setShopNames(map)
        })
    }, [])

    function handleSubmit(item: Item) {
        //TODO Add Error Handling
        shopperApi?.createItem(item).then(item => {
            handleClose();
        })
    }

    function handleFileSelect(newFiles: File[]) {

        newFiles.forEach(file => {
            if (knownFiles.filter(knownFile => file.name === knownFile.name).length === 0) {
                console.log("Upload " + file)
                shopperApi?.uploadImage(file).then(id => {
                    form.insertListItem("images", id);
                })
            } else {
                console.log("Ignore File " + file)
            }
        })

        setFiles(newFiles)
        setKnownFiles(newFiles)
    }

    function handleUploadDelete(id: string) {
        shopperApi?.deleteUploadedImage(id).then(() => {
            for (let i = 0; i < form.values.images.length; i++) {
                if (form.values.images[i] === id) {
                    form.removeListItem("images", i);
                }
            }
        })
    }

    function handleFetchItemInformation() {
        shopperApi?.fetchItemData(form.values.url).then(item => {
            form.setValues({
                name: item.name,
                url: item.url,
                shop: item.shop,
                price: item.price,
                currency: item.currency ? item.currency : "EUR",
                description: item.description,
                images: item.images
            })
        })
    }

    function handleImageByURL() {
        shopperApi?.loadImageByUrl(imageURL).then(id => {
            form.insertListItem("images", id);
            setImageURL("");
        })
    }

    function handleClose() {
        setFiles([])
        setKnownFiles([])
        form.reset();
        close();
        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <>
            <Drawer opened={opened} onClose={handleClose} title={props.title ? props.title : "Create Item"}>
                <form onSubmit={form.onSubmit((values) => handleSubmit({...values} as Item))}>
                    <TextInput
                        required
                        withAsterisk
                        label="Name"
                        placeholder="Item Name"
                        {...form.getInputProps('name')}
                    />

                    <TextInput
                        required
                        withAsterisk
                        label="URL"
                        placeholder="Item URL"
                        {...form.getInputProps('url')}
                        rightSectionWidth={"60px"}
                        rightSection={<Button onClick={() => handleFetchItemInformation()}>
                            <FontAwesomeIcon icon={faCrosshairs}/></Button>}
                    />

                    <Select
                        required
                        label="Shop"
                        placeholder="Shop"
                        data={shopNames}
                        searchable
                        readOnly={!!props.preselectedShop}
                        defaultValue={props.preselectedShop ? props.preselectedShop : ""}
                        {...form.getInputProps('shop')}
                    />

                    <CurrencyInput valueProps={{...form.getInputProps("price")}}
                                   currencyProps={{...form.getInputProps("currency")}}/>

                    <Textarea
                        label="Description"
                        placeholder="Item Description"
                        {...form.getInputProps('description')}
                    />

                    <MultiSelect
                        label="Item tags"
                        placeholder="Tags"
                        searchable
                        clearable
                        nothingFoundMessage="Nothing found..."
                        data={tagGroups}
                        {...form.getInputProps('tags')}
                    />

                    <TextInput
                        label="Add Image by URL"
                        placeholder="Image URL"
                        value={imageURL}
                        onChange={(value) => setImageURL(value.currentTarget.value)}
                        rightSection={<Button onClick={() => handleImageByURL()}>
                            <FontAwesomeIcon icon={faCrosshairs}/></Button>}
                    />

                    <FileInput label="Upload files"
                               multiple
                               value={files}
                               defaultValue={files}
                               accept="image/png,image/jpeg"
                               onChange={(values) => handleFileSelect(values)}/>

                    <Space h="md"/>

                    <Grid>
                        {form.values.images.map(image => {
                            return (
                                <Grid.Col span={6} key={image}>
                                    <Card withBorder>
                                        <Card.Section>
                                            <Image
                                                src={optHelper?.prepareURL(apiConfig?.shopper + "image/delivery/" + image)}
                                            />
                                            <Button color="red" fullWidth radius={"xs"}
                                                    onClick={() => handleUploadDelete(image)}>
                                                Delete
                                            </Button>
                                        </Card.Section>
                                    </Card>
                                </Grid.Col>)
                        })}
                    </Grid>

                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Create</Button>
                    </Group>
                </form>
            </Drawer>

            <Button onClick={open} fullWidth>{props.title ? props.title : "Create Item"}</Button>
        </>
    );
}