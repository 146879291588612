import {ManagerApi} from "./ManagerApi";
import {TOTP, URI} from "otpauth";
import Keycloak from "keycloak-js";
import {sha512} from "js-sha512";

export class OtpHelper {

    private managerApi: ManagerApi;
    private keycloak: Keycloak;

    private totp: TOTP | undefined;

    constructor(managerApi: ManagerApi, keycloak: Keycloak) {
        this.managerApi = managerApi;
        this.keycloak = keycloak;
        this.init();

        setInterval(()=>this.init(), 300000)
    }

    init() {
        this.managerApi.getOTPSecret().then(tokenURI => {
            this.totp = URI.parse(tokenURI) as TOTP;
        })
    }

    prepareURL(source: string) {
        const data = this.totp?.generate() + ":" + this.keycloak.tokenParsed?.iat + ":" + this.keycloak.tokenParsed?.sub;
        const hash = sha512(data);

        return source + "?hash=" + btoa(hash + ":" + this.keycloak.tokenParsed?.jti + ":" + Date.now());
    }

}