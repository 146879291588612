import {Container} from "@mantine/core";
import React from "react";
import TagTable from "../../compoments/shopper/tag/TagTable";

export default function Tags() {
    return (
        <Container fluid>
            <TagTable/>
        </Container>
    );
}