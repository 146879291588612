import {Text, Progress, Card} from '@mantine/core';
import classes from './ProgressCardColored.module.css';
import React from "react";

interface ProgressCardColoredProps {
    total: number
    progress: number
    title: string
    render?: (value: number) => React.ReactNode
}

export function ProgressCardColored({total, progress, render, title}: Readonly<ProgressCardColoredProps>) {
    return (
        <Card withBorder radius="md" h={"100%"} className={classes.card} w={"100%"}>
            <Text tt="uppercase" fw={700} className={classes.title}>
                {title}
            </Text>
            <Text fz="lg" fw={500} className={classes.stats}>
                {render ? render(progress) : progress} / {render ? render(total) : total}
            </Text>
            <Progress
                value={progress / (total / 100)}
                mt="md"
                size="lg"
                radius="xl"
                classNames={{
                    root: classes.progressTrack,
                    section: classes.progressSection,
                }}
            />
        </Card>
    );
}