import {NumberInput, rem, Select} from '@mantine/core';

const data = [
    {value: 'EUR', label: '🇪🇺 EUR'},
    {value: 'USD', label: '🇺🇸 USD'},
    {value: 'CAD', label: '🇨🇦 CAD'},
    {value: 'GBP', label: '🇬🇧 GBP'},
    {value: 'AUD', label: '🇦🇺 AUD'},
];


interface CurrencyInputProps {
    valueProps: {}
    currencyProps: {}
}

export function CurrencyInput(props: Readonly<CurrencyInputProps>) {
    const select = (
        <Select
            required
            data={data}
            rightSectionWidth={28}
            styles={{
                input: {
                    fontWeight: 500,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    width: rem(92),
                    marginRight: rem(-2),
                },
            }}
            {...props.currencyProps}
        />
    );

    return (
        <NumberInput
            required
            placeholder="1000"
            label="Price"
            rightSection={select}
            rightSectionWidth={92}
            decimalScale={2}
            fixedDecimalScale
            {...props.valueProps}
        />
    );
}