import {Dropzone, FileRejection, FileWithPath} from "@mantine/dropzone";
import {Group, Text} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faUpload} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const ALLOWED_MIME_TYPE = ["image/png", "image/gif", "image/jpeg", "image/svg+xml", "image/webp", "image/avif", "application/pdf"];

export function FileUploadDropzone(props: Readonly<{
    onDrop: (files: FileWithPath[]) => void,
    onReject: (files: FileRejection[]) => void
}>) {
    return <Dropzone
        onDrop={props.onDrop}
        onReject={props.onReject}
        maxSize={50 * 1024 ** 2}
        accept={ALLOWED_MIME_TYPE}
    >
        <Group justify="center" gap="xl" mih={220} style={{pointerEvents: "none"}}>
            <Dropzone.Accept>
                <FontAwesomeIcon icon={faUpload}/>
            </Dropzone.Accept>
            <Dropzone.Reject>
                <FontAwesomeIcon icon={faCircleXmark}/>
            </Dropzone.Reject>
            <Dropzone.Idle>
                <FontAwesomeIcon icon={faUpload}/>
            </Dropzone.Idle>

            <div>
                <Text size="xl" inline>
                    Drag images here or click to select files
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                    Attach as many files as you like, each file should not exceed 50mb
                </Text>
            </div>
        </Group>
    </Dropzone>;
}