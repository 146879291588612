import {ApiConfig, Page, Pageable} from "../type/Base";
import {Collection, CollectionItem, CollectionItemInputContainer, Item, Shop, ShopName, Tag} from "../type/Shopper";
import {ComboboxData} from "@mantine/core";
import {RequestHelper} from "./RequestHelper";
import {APIManager} from "./APIManager";
import {ErrorHandler} from "./ErrorHandler";

export class ShopperApi {

    private apiConfig: ApiConfig
    private requestHelper: RequestHelper
    private errorHandler: ErrorHandler

    constructor(apiConfig: ApiConfig, requestHelper: RequestHelper, errorHandler: ErrorHandler) {
        this.apiConfig = apiConfig;
        this.requestHelper = requestHelper;
        this.errorHandler = errorHandler;
    }

    listItems = (pageable: Pageable, search?: string, shop?: string, tag?: string): Promise<Page<Item>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (search && search !== "") {
            params.set("search", search);
        }
        if (shop && shop !== "") {
            params.set("shop", shop);
        }
        if (tag && tag !== "") {
            params.set("tag", tag);
        }
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        } else {
            params.set("sort", "name")
        }
        return this.requestHelper.fetch(this.apiConfig.shopper + "item?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return this.errorHandler.handleError(response);
            }
        })
    }

    getItem = (id: string): Promise<Item> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "item/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    createItem = (item: Item): Promise<Item> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "item", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(item)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteItem = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "item/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    //Try to fetch a much information about the item as possibly
    fetchItemData = (url: string): Promise<Item> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "fetch", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify([url])
        }).then(response => {
            if (response.ok) {
                return response.json().then(json => {
                    if (json.length === 1) {
                        return Promise.resolve(json[0]);
                    } else {
                        return Promise.resolve();
                    }
                })
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    //Upload the image to the temp storage
    uploadImage = (file: File): Promise<string> => {
        let arrayBuffer = file.arrayBuffer();

        return arrayBuffer.then(buffer => {
            return this.requestHelper.fetch(this.apiConfig.shopper + "image/upload", {
                method: "POST",
                headers: [
                    {
                        name: "Content-Type",
                        value: file.type
                    }
                ],
                body: buffer
            }).then(response => {
                if (response.ok) {
                    return response.json().then(json => {
                        return Promise.resolve(json.id)
                    })
                } else {
                    //TODO Add error handling
                    return Promise.resolve("");
                }
            })
        })
    }

    loadImageByUrl = (url: string): Promise<string> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "image/url", {
            method: "POST",
            body: url
        }).then(response => {
            if (response.ok) {
                return response.json().then(json => {
                    return Promise.resolve(json.id)
                })
            } else {
                //TODO Add error handling
                return Promise.resolve("");
            }
        })
    }

    deleteUploadedImage = (id: string): Promise<void> => {
        this.requestHelper.fetch(this.apiConfig.shopper + "image/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
        return Promise.resolve();
    }


    //Shop
    listShops = (pageable: Pageable, search: string): Promise<Page<Shop>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (search !== "") {
            params.set("search", search);
        }
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        }
        return this.requestHelper.fetch(this.apiConfig.shopper + "shop?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return this.errorHandler.handleError(response);
            }
        })
    }

    getShop = (id: string): Promise<Shop> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "shop/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    listShopNames = (): Promise<ShopName[]> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "shop/names").then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    createShop = (shop: Shop): Promise<Shop> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "shop", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(shop)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteShop = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "shop/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    //Tags
    listTags = (pageable: Pageable, search?: string, groupsOnly?: boolean): Promise<Page<Tag>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (groupsOnly) {
            params.set("groups", String(groupsOnly));
        }
        if (search && search !== "") {
            params.set("search", search);
        }
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        }
        return this.requestHelper.fetch(this.apiConfig.shopper + "tag?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return this.errorHandler.handleError(response);
            }
        })
    }

    listTagsGroups = (): Promise<ComboboxData> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "tag/groups").then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    getTag = (id: string): Promise<Tag> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "tag/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    createTag = (tag: Tag): Promise<Tag> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "tag", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(tag)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteTag = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "tag/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }


    //Collections
    listCollections = (pageable: Pageable, search?: string): Promise<Page<Collection>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));
        if (search && search !== "") {
            params.set("search", search);
        }
        if (pageable.sort) {
            params.set("sort", pageable.sort);
        }
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return this.errorHandler.handleError(response);
            }
        })
    }

    getCollection = (id: string): Promise<Collection> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/" + id).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    createCollection = (collection: Collection): Promise<Collection> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(collection)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteCollection = (id: string): Promise<void> => {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    //Collection Items
    listCollectionItems = (pageable: Pageable, collection: string, search?: string): Promise<Page<CollectionItem>> => {
        let params = new URLSearchParams();
        params.set("size", String(pageable.size));
        params.set("page", String(pageable.page));

        if (search && search !== "") {
            params.set("search", search);
        }

        if (pageable.sort) {
            params.set("sort", pageable.sort);
        } else {
            params.set("sort", "item.name")
        }

        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/item/" + collection + "?" + params).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return this.errorHandler.handleError(response);
            }
        })
    }

    addCollectionItem(item: CollectionItemInputContainer): Promise<CollectionItem> {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/item", {
            method: "POST",
            headers: [
                {
                    name: "Content-Type",
                    value: "application/json"
                }
            ],
            body: JSON.stringify(item)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    deleteCollectionItem(id: string) {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/item/" + id, {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return Promise.resolve();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }

    boughtCollectionItem(id: string, bought: boolean): Promise<CollectionItem> {
        return this.requestHelper.fetch(this.apiConfig.shopper + "collection/item/" + id + "/bought?bought=" + bought, {
            method: "POST"
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //TODO Add error handling
                return Promise.resolve();
            }
        })
    }


}