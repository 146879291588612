import React, {useContext, useEffect, useState} from 'react';
import {
    Table,
    ScrollArea,
    Text,
    TextInput,
    Grid, GridCol, ActionIcon, NumberFormatter
} from '@mantine/core';
import CreateItem from "./CreateItem";
import {useNavigate} from "react-router-dom";
import classes from "../Table.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {APIManagerContext} from "../../../api/APIManager";
import {EMPTY_PAGE, Page} from "../../../type/Base";
import {Item} from "../../../type/Shopper";
import {Th} from "../SortableTable";
import LegionPagination from "../../LegionPagination";
import {modals} from "@mantine/modals";

interface ItemTableProps {
    shop?: string
    tag?: string
}

export default function ItemTable(props: Readonly<ItemTableProps>) {

    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState<keyof Item | null>("name");
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const [currentEditing, setCurrentEditing] = useState<string>();

    const [tags, setTags] = useState<any>({});

    const [data, setData] = useState<Page<Item>>(EMPTY_PAGE);

    const {shopperApi} = useContext(APIManagerContext);

    const navigate = useNavigate();

    useEffect(() => {
        reloadContent(data.number);
    }, [reverseSortDirection, search, sortBy]);

    function reloadContent(page?: number) {
        const newPage = page || 0;

        setCurrentEditing(undefined);
        shopperApi?.listItems({
            page: newPage,
            size: 10,
            sort: sortBy + "," + (reverseSortDirection ? "desc" : "asc")
        }, search, props.shop, props.tag).then(response => {
            setData(response)
        })

        shopperApi?.listTags({page: 0, size: 100}).then(response => {
            let tagNames: any = {};
            response.content.forEach(tag => {
                tagNames[tag.id] = tag.label;
            })

            setTags(tagNames)
        })
    }


    const setSorting = (field: keyof Item) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setSearch(value);
    };

    function handleDelete(id: string) {
        shopperApi?.deleteItem(id).then(() => {
            reloadContent();
        })
    }

    const rows = data.content.map((row) => (
        <Table.Tr key={row.id}>
            <Table.Td visibleFrom={"xs"}
                      onClick={() => navigate("/shopper/item/" + row.id)}>{row.id.substring(0, 6)}</Table.Td>
            <Table.Td onClick={() => navigate("/shopper/item/" + row.id)}>{row.name}</Table.Td>
            <Table.Td onClick={() => navigate("/shopper/item/" + row.id)}>
                <NumberFormatter value={row.price} thousandSeparator="." decimalSeparator=","
                                 suffix={" " + row.currency}/>
            </Table.Td>
            <Table.Td onClick={() => navigate("/shopper/item/" + row.id)}>{row.tags.map(tag => {
                return (tags[tag])
            }).join(", ")}</Table.Td>
            <Table.Th pt={"xs"} pb={"xs"}>
                <ActionIcon variant="filled" mr={"xs"} aria-label="Settings" onClick={(event) => {
                    setCurrentEditing(row.id)
                }}>
                    <FontAwesomeIcon icon={faPenToSquare}/>
                </ActionIcon>
                <ActionIcon variant="filled" mr={"xs"} mb={"5px"} mt={"5px"} color={"red"} aria-label="Settings"
                            onClick={(event) => {
                                modals.openConfirmModal({
                                    title: "Delete " + row.name,
                                    centered: true,
                                    children: (<Text> Are you sure you want to delete this Item?</Text>),
                                    labels: {confirm: 'Delete Item', cancel: "No don't delete it"},
                                    confirmProps: {color: 'red'},
                                    onConfirm: () => handleDelete(row.id),
                                });

                            }}>
                    <FontAwesomeIcon icon={faTrash}/>
                </ActionIcon>
            </Table.Th>
        </Table.Tr>
    ));

    return (
        <ScrollArea scrollbars={"y"}>
            <Grid mb={"xs"}>
                <GridCol span={{base: 12, md: 8}}>
                    <TextInput
                        placeholder="Search by name"
                        value={search}
                        onChange={handleSearchChange}
                    />
                </GridCol>
                <GridCol span={{base: 12, md: 4}}>
                    <CreateItem onClose={() => reloadContent(data.number)} preselectedShop={props.shop}
                                edit={currentEditing} autoOpen={true}/>
                </GridCol>
            </Grid>
            <Table striped highlightOnHover withTableBorder>
                <Table.Tbody>
                    <Table.Tr>
                        <Th visibleFrom={"xs"}
                            sorted={sortBy === 'id'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('id')}
                        >
                            #
                        </Th>
                        <Th
                            sorted={sortBy === 'name'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('name')}
                        >
                            Name
                        </Th>
                        <Th
                            sorted={sortBy === 'price'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('price')}
                        >
                            Price
                        </Th>
                        <Th
                            sorted={sortBy === 'tags'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('tags')}
                        >
                            Tags
                        </Th>
                        <Table.Th className={classes.th}>
                            Actions
                        </Table.Th>
                    </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>
                    {rows.length > 0 ? (
                        rows
                    ) : (
                        <Table.Tr>
                            {/**@ts-ignore*/}
                            <Table.Td>
                                <Text fw={500} ta="center">
                                    Nothing found
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
            <LegionPagination onChange={(page) => {
                reloadContent(page - 1)
            }} total={data.totalPages} value={data.number + 1}/>
        </ScrollArea>
    );
}