import React, {useState} from 'react';
import {
    AppShell,
    Burger, Code,
    createTheme,
    Group, Loader,
    MantineColorsTuple,
    MantineProvider, Stack, Title
} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";

import "@mantine/core/styles.css";
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';

import {BrowserRouter} from "react-router-dom";
import Router from "./Router";
import {NavbarNested} from "./compoments/navbar/Navbar";
import {APIManager, APIManagerContext} from "./api/APIManager";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";
import SpotlightSearch from "./compoments/search/SpotlightSearch";


const myColor: MantineColorsTuple = [
    '#fff4e2',
    '#ffe9cc',
    '#ffd09c',
    '#fdb766',
    '#fca13a',
    '#fb931d',
    '#fc8c0c',
    '#e17900',
    '#c86a00',
    '#ae5a00'
];

const theme = createTheme({
    colors: {
        myColor,
    }

});

function App() {
    const [ready, setReady] = useState<boolean>(false);

    const [apiManager, setApiManager] = useState<APIManager>(new APIManager(() => onAPIReady()));

    const [opened, {toggle}] = useDisclosure();

    function onAPIReady() {
        if (!ready) {
            setReady(true)
        }
    }

    function appBody() {
        return (
            <AppShell
                header={{height: 60}}
                navbar={{width: 300, breakpoint: 'sm', collapsed: {mobile: !opened}}}
                padding="md"
            >
                <AppShell.Header bg={"#ae5a00"} display={"inline-flex"}>

                    <Group w={"80%"} h={"100%"}>
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>
                        <Title>Legion</Title>
                        <Code fw={700}>{apiManager.apiConfig?.version}</Code>
                    </Group>
                    <Group w={"20%"} h={"100%"} justify={"end"} mr={"xs"}>
                        <SpotlightSearch/>
                    </Group>
                </AppShell.Header>
                <AppShell.Navbar>
                    <NavbarNested/>
                </AppShell.Navbar>
                <AppShell.Main>
                    <Router/>
                </AppShell.Main>
            </AppShell>
        )
    }

    function handleAuthenticationReady() {
        console.log("Start Api Manager")
        apiManager.init();
    }

    function loader() {
        return (
            <Stack justify={"center"} align={"center"} h={"100vh"}>
                <Loader color="blue" size={100}/>
            </Stack>
        )
    }

    return (
        <BrowserRouter>
            <APIManagerContext.Provider value={apiManager}>
                <ReactKeycloakProvider authClient={apiManager.keycloak}
                                       initOptions={{onLoad: 'login-required'}}
                                       onTokens={() => handleAuthenticationReady()}
                >
                    <MantineProvider theme={theme} defaultColorScheme={"dark"}>
                        <ModalsProvider>
                            <Notifications/>
                            {ready ? appBody() : loader()}
                        </ModalsProvider>
                    </MantineProvider>
                </ReactKeycloakProvider>
            </APIManagerContext.Provider>
        </BrowserRouter>
    );
}

export default App;
