import React, {useContext, useState} from "react";
import {Button, Modal, TextInput} from "@mantine/core";
import {APIManagerContext} from "../../api/APIManager";
import {useDisclosure} from "@mantine/hooks";
import {ContentTag} from "../../type/Content";

interface CreateTagModalProps {
    parent?: string
    afterAction: () => void
}

export default function CreateTagModal({parent, afterAction}: Readonly<CreateTagModalProps>) {
    const [tag, setTag] = useState<string>("")

    const {contentApi} = useContext(APIManagerContext);

    const [opened, {open, close}] = useDisclosure(false);

    return (
        <>
            <Modal opened={opened} onClose={close} title="Create Tag">
                <TextInput label="Name" placeholder="New Tag" data-autofocus
                           onChange={(value) => setTag(value.currentTarget.value)}/>
                <Button fullWidth disabled={tag === ""} onClick={() => {
                    let buildTag: ContentTag = {
                        id: "",
                        label: tag
                    }

                    if (parent) {
                        buildTag.parent = parent
                    }

                    contentApi?.createTag(buildTag).then(response => {
                        close()
                        afterAction()
                    })
                }} mt="md">
                    Submit
                </Button>
            </Modal>

            <Button w={"100%"} mt={"xs"} onClick={open}>Create Tag</Button>
        </>
    )
}