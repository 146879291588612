export type Page<T> = {
    content: T[]
    last: boolean
    first: boolean
    numberOfElements: number
    totalElements: number
    totalPages: number
    size: number
    number: number
}

export const EMPTY_PAGE: Page<any> = {
    content: [],
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
};

export type Pageable = {
    size: number
    page: number
    sort?: string
}


export type ApiConfig = {
    version: string
    manager: string
    shopper: string
    content: string
}

export type KeycloakUserInfo = {
    sub: string
    name: string
    email: string
    preferred_username: string
    given_name: string
    family_name: string
    groups: string[]
    email_verified: boolean
    realm_access: KeycloakUserInfoRealm[]
}

export type KeycloakUserInfoRealm = {
    roles: string[]
}

export type  RequestConfig = {
    method: string
    headers?: { name: string, value: string }[]
    body?: any
}