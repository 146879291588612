import React, {useContext, useEffect, useState} from "react";
import {Button, Grid, Modal} from "@mantine/core";
import {APIManagerContext} from "../../api/APIManager";
import {useDisclosure} from "@mantine/hooks";
import {ContentGridElement} from "../../type/Content";
import ContentGridItem from "./ContentGridItem";
import ContentPathBreadcrumb from "./ContentPathBreadcrumb";

interface SelectTagModalProps {
    afterAction: (selected: string | undefined) => void
}

export default function SelectTagModal({afterAction}: Readonly<SelectTagModalProps>) {
    const [selected, setSelected] = useState<string | undefined>(undefined)

    const [tags, setTags] = useState<ContentGridElement[]>([]);

    const {contentApi} = useContext(APIManagerContext);

    const [opened, {open, close}] = useDisclosure(false);


    useEffect(() => {
        if (opened) {
            contentApi?.listTags({page: 0, size: 20}, selected).then(response => {
                setTags(response.content.map(tag => ({
                    id: tag.id,
                    label: tag.label,
                    parent: [tag.parent],
                    thumbnail: tag.thumbnail,
                    type: "tag"
                })));
            })
        }
    }, [opened, selected]);


    return (
        <>
            <Modal opened={opened} onClose={close} title="Select Tag" fullScreen>
                <ContentPathBreadcrumb tag={selected} onClick={(contentPath) => setSelected(contentPath.id)}
                                       onRootClick={() => setSelected("")}/>
                <Grid>
                    {tags.map(tag => {
                        return (<ContentGridItem key={tag.id} content={tag} hideDelete afterAction={() => {
                        }} colSpan={{base: 6, md: 3, xl: 2}}
                                                 onClick={(event, content) => {
                                                     event.preventDefault();
                                                     setSelected(content.id)
                                                 }}/>)
                    })}
                </Grid>
                <Button fullWidth onClick={() => {
                    close()
                    afterAction(selected)
                }} mt="md">
                    Select
                </Button>
            </Modal>

            <Button w={"100%"} mt={"xs"} onClick={open}>Add Tag</Button>
        </>
    )
}