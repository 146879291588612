import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Shop} from "../../type/Shopper";
import {APIManagerContext} from "../../api/APIManager";
import {
    Card, Center,
    Title
} from "@mantine/core";
import ItemTable from "../../compoments/shopper/item/ItemTable";

export default function ShopViewer() {
    const {id} = useParams();

    const {shopperApi} = useContext(APIManagerContext);

    const [shop, setShop] = useState<Shop>({
        id: "", location: "", name: "", tags: [], url: ""
    });


    useEffect(() => {
        if (id) {
            shopperApi?.getShop(id).then(response => {
                setShop(response)
            })
        }
    }, [id])

    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Center p={"md"}>
                <Title>{shop.name}</Title>
            </Center>
            <ItemTable shop={id}/>
        </Card>
    );
}