import {useParams} from "react-router-dom";
import {Card} from "@mantine/core";
import BackButton from "../../compoments/BackButton";
import React, {useContext, useEffect, useState} from "react";

import {AggregateContent} from "../../type/Content";
import {APIManagerContext} from "../../api/APIManager";
import ImageViewer from "../../compoments/content/viewer/ImageViewer";
import PDFViewer from "../../compoments/content/viewer/PDFViewer";
import ContentEditor from "../../compoments/content/ContentEditor";

export default function ContentViewer() {
    const {id} = useParams();

    const {contentApi} = useContext(APIManagerContext);

    const [content, setContent] = useState<AggregateContent>({
        content: "",
        contentType: "",
        createDate: new Date(),
        fileName: "",
        hash: "",
        id: "",
        size: 0,
        tags: [],
        uploadDate: new Date(),
        version: "",
        name: ""
    })

    useEffect(() => {
        if (id) {
            contentApi?.getContent(id).then(response => {
                setContent(response)
            })
        }
    }, [id]);


    function scroll() {
        if (window.location.hash === "#edit") {
            const element = document.querySelector('#edit')
            if (element) {
                const rect = element.getBoundingClientRect() // get rects(width, height, top, etc)
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                window.scroll({
                    top: rect.top + rect.height / 2 - viewHeight / 2,
                    behavior: 'smooth' // smooth scroll
                });
            }
        }
    }


    function selectViewer() {
        switch (content.contentType.toLowerCase()) {
            case "image/png":
            case "image/jpeg":
            case "image/webp":
            case "image/gif":
                return <ImageViewer content={content} callback={() => scroll()}/>;
            case "application/pdf":
                return <PDFViewer content={content} callback={() => scroll()}/>;
            default:
                return (<h1>No Content Viewer for Type {content.contentType} Found.</h1>)
        }
    }

    return (
        <Card h={"100%"}>
            <Card.Section>
                <BackButton/>
            </Card.Section>
            <Card.Section>
                {selectViewer()}
            </Card.Section>
            <Card.Section m={"xs"}>
                <ContentEditor content={content} id={"edit"}/>
            </Card.Section>
        </Card>
    )
}
