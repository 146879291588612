import {AggregateContent, ContentGridElement, ContentTag} from "../../type/Content";
import {Card, Center, Grid, GridCol, Menu, Table, Title} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import {APIManagerContext} from "../../api/APIManager";
import ContentGridItem from "./ContentGridItem";
import AddTagToContentModal from "./AddTagToContentModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

interface ContentEditorProps {
    content: AggregateContent
    id?: string
}

export default function ContentEditor({content, id}: Readonly<ContentEditorProps>) {

    const [tags, setTags] = useState<ContentGridElement[]>([]);

    const {contentApi} = useContext(APIManagerContext);


    useEffect(() => {
        reloadTags()
    }, [content]);

    function reloadTags() {
        Promise.all(
            content.tags.map(id => contentApi?.getTag(id)))
            .then(responses => {
                let filter: ContentTag[] = responses.filter(response => response !== undefined) as ContentTag[];

                return filter
                    .map((response): ContentGridElement => ({
                        id: response.id,
                        label: response.label,
                        thumbnail: response.thumbnail,
                        parent: [response.parent],
                        type: "tag"
                    }))
            }).then(response => {
            setTags(response)
        })
    }

    function bytesToSize(bytes: number) {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 'n/a';
        let i: number = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    function handleRemoveTag(id: string) {
        let newTags = content.tags.filter(tag => tag !== id);
        contentApi?.updateContent({
            ...content,
            tags: newTags
        }).then(response => {
            content.tags = newTags;
            reloadTags()
        })
    }

    function removeTagMenuItem(id: string): React.ReactNode[] {
        return [(<Menu.Item key={"delete"} color="red" leftSection={<FontAwesomeIcon icon={faTrash}/>}
                            onClick={() => handleRemoveTag(id)}>Remove Tag</Menu.Item>)];
    }

    return (
        <Card.Section id={id}>
            <Center>
                <Title mt={"xs"} size={"xx-large"}>
                    {content.name}
                </Title>
            </Center>
            <Grid>
                <GridCol order={{md: 10, lg: 2}} span={{md: 12, lg: 6}}>
                    <Center>
                        <Table w={"100%"} m={"xs"} withTableBorder withRowBorders withColumnBorders>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td>Type</Table.Td>
                                    <Table.Td>{content.contentType}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Size</Table.Td>
                                    <Table.Td>{bytesToSize(content.size)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Hash</Table.Td>
                                    <Table.Td>{content.hash}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Upload Date</Table.Td>
                                    <Table.Td>{new Date(content.uploadDate).toLocaleString()}</Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                    </Center>
                </GridCol>

                <GridCol order={{md: 5, lg: 1}} span={{md: 12, lg: 6}}>
                    <AddTagToContentModal afterAction={(selected) => {
                        if (selected) {
                            content.tags.push(selected)
                            reloadTags()
                        }
                    }} content={content}/>
                    <Center>
                        <Grid>
                            {tags.map(tag => {
                                return (
                                    <ContentGridItem key={tag.id} content={tag} colSpan={{base: 6, lg: 4}}
                                                     viewedContent={content} hideDelete={true}
                                                     additionalMenuItems={removeTagMenuItem}
                                                     afterAction={() => {
                                                         reloadTags()
                                                     }}/>)
                            })}
                        </Grid>
                    </Center>
                </GridCol>
            </Grid>
        </Card.Section>
    )
}